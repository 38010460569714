
import Vue from "vue";
import ProfileData from "@/components/Profile/ProfileData.vue";
import MentorStatistics from "@/components/Mentor/MentorStatistics.vue";
import { mapGetters } from "vuex";
import MentoreeStatistics from "../components/Mentoree/MentoreeStatistics.vue";
import ProfileDataLoading from "../components/Profile/ProfileDataLoading.vue";
import StatisticsDataLoading from "../components/Statistics/StatisticsDataLoading.vue";

export default Vue.extend({
  name: "StatisticsPage",
  components: {
    StatisticsDataLoading,
    ProfileData,
    MentorStatistics,
    MentoreeStatistics,
    ProfileDataLoading
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      mentorStatistics: "mentor/getStatistics",
      menteeStatistics: "mentee/getStatistics"
    })
  },
  mounted() {
    const dispatchers = {
      mentor: {
        user: "mentor/GET_MENTOR",
        statistic: "mentor/GET_STATISTICS"
      },
      mentee: {
        user: "mentee/GET_MENTEE",
        statistic: "mentee/GET_STATISTICS"
      }
    } as any;

    const profileType = this.profile.type;

    if (!dispatchers[profileType]) {
      throw new Error("The given profile type does not exits. " + profileType);
    }

    this.$store
      .dispatch(dispatchers[profileType].user, {
        id: this.profile.uuid,
        page: 1
      })
      .then(() => {
        this.$store.dispatch(
          dispatchers[profileType].statistic,
          this.profile.uuid
        );
      });
  }
});
